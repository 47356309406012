<template>
  <div>
    <a-collapse
      class="travel-filters mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>

        <a-row class="fiter-collumns" :gutter="20">
          <a-col class="col1 mt-0" :span="12">
            <a-row :gutter="20">
              <a-col :span="12">
                <div class="travel-input">
                  <label class="filled">Data de cadastro</label>

                  <a-range-picker
                    v-model="optFilters.period.selected"
                    :format="['DD/MM/YYYY']"
                    :value-format="'YYYY-MM-DD'"
                  />
                </div>
              </a-col>

              <a-col :span="12">
                <div class="travel-input">
                  <label class="filled">Cliente </label>
                  <a-auto-complete
                    :data-source="
                      customers.list.map(
                        ({
                          first_name,
                          last_name,
                          id,
                          person_type,
                          trading_name,
                        }) => ({
                          value: id,
                          text:
                            person_type === 'Pessoa Física'
                              ? `${id} - ${first_name} ${last_name}`
                              : `${id} - ${trading_name}`,
                        })
                      )
                    "
                    v-model="customers.filters.customerName"
                    style="width: 100%; height: 32px"
                    placeholder="Buscar cliente..."
                    @change="onChangeSearchCustomers"
                    @select="customerSelected"
                    @blur="customerSelected"
                  />
                </div>
              </a-col>
              <a-col :span="24">
                <div class="travel-input">
                  <label class="filled">Origem</label>

                  <a-select
                    class="travel-input"
                    placeholder="Selecione"
                    v-model="optFilters.origin.selected"
                    show-search
                    allow-clear
                    mode="multiple"
                    style="width: 100%"
                  >
                    <aSelectOption
                      v-for="(origin, index) in originsList"
                      :key="index"
                      :value="origin.name"
                    >
                      {{ origin.name }}
                    </aSelectOption>
                  </a-select>
                </div>
              </a-col>

              <a-col :span="12">
                <div class="field travel-checkbox">
                  <font class="label relative" style="top: -17px"
                    >Tipo de cliente</font
                  >
                  <a-checkbox-group
                    style="width: 100%"
                    v-model="optFilters.customers.type"
                  >
                    <a-checkbox class="f12" value="Novo"> Novo </a-checkbox>
                    <a-checkbox class="f12" value="Recorrente">
                      Recorrente
                    </a-checkbox>
                  </a-checkbox-group>
                </div>
              </a-col>

              <a-col :span="12">
                <div class="field travel-checkbox">
                  <font class="label relative" style="top: -17px"
                    >Contrato/Venda</font
                  >
                  <a-checkbox-group
                    style="width: 100%"
                    v-model="optFilters.haveContractAndSale"
                  >
                    <a-checkbox class="f12" value="Sim"> Sim </a-checkbox>
                    <a-checkbox class="f12" value="Não"> Não </a-checkbox>
                  </a-checkbox-group>
                </div>
              </a-col>
            </a-row>
          </a-col>

          <a-col class="mt-0" :span="12">
            <a-col :span="6">
              <div class="travel-input relative">
                <label class="filled">UTM Source</label>
                <a-input
                  placeholder="Escreva"
                  v-model="optFilters.utm.source"
                />
              </div>
            </a-col>

            <a-col :span="6">
              <div class="travel-input relative">
                <label class="filled">UTM Campaign</label>
                <a-input
                  placeholder="Escreva"
                  v-model="optFilters.utm.campaign"
                />
              </div>
            </a-col>

            <a-col :span="6">
              <div class="travel-input relative">
                <label class="filled">UTM Medium</label>
                <a-input
                  placeholder="Escreva"
                  v-model="optFilters.utm.medium"
                />
              </div>
            </a-col>

            <a-col :span="6">
              <div class="travel-input relative">
                <label class="filled">UTM Term</label>
                <a-input placeholder="Escreva" v-model="optFilters.utm.term" />
              </div>
            </a-col>

            <a-col :span="6">
              <div class="travel-input relative">
                <label class="filled">UTM Content</label>
                <a-input
                  placeholder="Escreva"
                  v-model="optFilters.utm.content"
                />
              </div>
            </a-col>
          </a-col>
        </a-row>
        <a-divider class="mt-0 mb-15" />
        <a-button type="primary" @click="onClickEmitGetOpportunities"
          ><a-icon type="search" /> Filtrar Oportunidades</a-button
        >
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
// libs
import _ from "lodash";

// mixins
import customerMixins from "@/components/customers/mixins/customerMixins";
import companyMixins from "@/mixins/companies/companyMixins";
import companyBranchMixins from "@/mixins/company-branches/companyBranchMixins";

export default {
  name: "CampaignsFilter",
  props: {
    optFilters: Object,
  },
  mixins: [customerMixins, companyMixins, companyBranchMixins],
  data() {
    return {
      originsList: [],
      contractAndSale: [{ label: "Sim", value: "Sim" }],
    };
  },
  mounted() {
    this.$http
      .get(`/category-entry/list?page=1&category_id=26&per_page=300`)
      .then(({ data }) => {
        this.originsList = data.data;
      });
  },
  methods: {
    onChangeSearchCustomers: _.debounce(function (e) {
      if (e.length > 1) {
        this.getCustomers();
      }
    }, 600),
    onClickEmitGetOpportunities() {
      this.$emit("onClickEmitGetOpportunities", this.optFilters);
    },
    customerSelected(customer) {
      this.customers.filters.customerName = customer;
      this.optFilters.customers.selected = [customer];
    },
  },
};
</script>

<style lang="sass" scoped>
.fiter-collumns
  .col1
    padding-right: 20px !important
    border-right: 1px solid #ddd

.travel-filters
  .field
    .label
      position: absolute
      font-size: 10px
      font-weight: 600
      color: #434a53 !important
      top: -10px
      z-index: 1
</style>
